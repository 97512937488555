"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDeployInfo = getDeployInfo;
const DEPLOYABLE_SERVER_TIMING_ENTRY_NAME = 'd';
function getDeployInfo(perf = window.performance) {
  try {
    const firstNavigationEntry = perf.getEntriesByType('navigation')[0];
    const serverTimingEntries = firstNavigationEntry.serverTiming;
    const description = serverTimingEntries.find(entry => entry.name === DEPLOYABLE_SERVER_TIMING_ENTRY_NAME).description;
    const [deployable, deployId] = description.split('#');
    if (deployable !== undefined && deployId !== undefined) {
      return {
        deployable,
        deployId
      };
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
}