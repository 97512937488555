"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = htmlTreeAsString;
const MAX_TRAVERSE_HEIGHT = 5;
const MAX_CLASSES_LENGTH = 60;
const ATTRIBUTE_WHITELIST = ['name', 'title', 'alt', 'data-test-id',
// Matches I18n string keys
'data-key'];

/**
 * Given a child DOM element, returns a query-selector statement describing that
 * and its ancestors
 * e.g. [HTMLElement] => body > div > input#foo.btn[name=baz]
 */
function htmlTreeAsString(target) {
  /* eslint no-extra-parens:0*/
  const out = [];
  const separator = ' > ';
  let currentElement = target;
  let height = 0;
  while (currentElement && height < MAX_TRAVERSE_HEIGHT) {
    const nextStr = htmlElementAsString(currentElement);
    if (nextStr === 'html') {
      break;
    }
    out.push(nextStr);
    height += 1;
    currentElement = currentElement.parentNode;
  }
  return out.reverse().join(separator);
}

/**
 * Returns a simple, query-selector representation of a DOM element
 * e.g. [HTMLElement] => input#foo.btn[name=baz]
 */
function htmlElementAsString(element) {
  const out = [];
  if (!element || !element.tagName) {
    return '';
  }
  out.push(element.tagName.toLowerCase());
  if (element.id) {
    out.push(`#${element.id}`);
  }
  for (const attribute of ATTRIBUTE_WHITELIST) {
    const value = element.getAttribute(attribute);
    if (value) {
      out.push(`[${attribute}="${value}"]`);
    }
  }
  let classesLength = 0;
  for (const className of element.classList) {
    // Filter out UIComponents private classes
    if (className.startsWith('private-')) {
      continue;
    }
    if (classesLength + className.length > MAX_CLASSES_LENGTH) {
      break;
    }
    classesLength += className.length;
    out.push(`.${className}`);
  }
  return out.join('');
}
module.exports = exports.default;